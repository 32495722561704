*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font: normal 1em / 1 -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
}

:root{
  --accent: rebeccapurple;
}